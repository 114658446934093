import {dialogsApi} from '../../utils/api';
import socket from "../../core/socket";

const Actions = {
    setDialogs: items => ({
        type: 'DIALOGS:SET_ITEMS',
        payload: items,
    }),
    setCategories: categories => ({
        type: 'CATEGORIES:SET_ITEMS',
        payload: categories,
    }),
    updateReadedStatus: ({userId, dialogId}) => ({
        type: 'DIALOGS:LAST_MESSAGE_READED_STATUS',
        payload: {
            userId,
            dialogId,
        },
    }),
    setCurrentDialogId: id => dispatch => {
        socket.emit('DIALOGS:JOIN', id);
        dispatch({
            type: 'DIALOGS:SET_CURRENT_DIALOG_ID',
            payload: id,
        });
    },
    fetchDialogs: () => dispatch => {
        console.log("fetchDialogs");
        dialogsApi.getAll().then(({data}) => {
            dispatch(Actions.setDialogs(data.dialogs));
        });
    },
    fetchArchive: () => dispatch => {
        console.log("fetchArchives");
        dialogsApi.getArchives().then(({data}) => {
            dispatch(Actions.setDialogs(data.dialogs));
        });
    },
    fetchSearch: (search) => dispatch => {
        console.log("fetchSearch", search);
        dialogsApi.search(search).then(({data}) => {
            dispatch(Actions.setDialogs(data.dialogs));
        });
    },
    confirmedDialog: (dialog_id) => {
        dialogsApi.confirmed({dialog_id: dialog_id}).then(({data}) => {
            window.location = "/dialog/" + dialog_id
        });
    },
    closeDialog: (dialog_id, child_id) => {
        socket.emit('DIALOGS:CLOSE', dialog_id);
        dialogsApi.close({dialog_id: dialog_id, child_id: child_id}).then(({data}) => {
            window.location = "/dialog/" + dialog_id
        });
    },
    setChildCategory: (dialog_id, cat_id) => {
        dialogsApi.setCategory({dialog_id: dialog_id, cat_id: cat_id}).then(({data}) => {
            window.location = "/dialog/" + dialog_id
        });
    },
    createDialog: (author, cat_id, text) => {
        dialogsApi.createDialog({author: author, cat_id: cat_id, text: text}).then(({data}) => {
            console.log(data);
            //window.location = "/";
        });
    },
    transferDialog: (dialog_id, cat_id) => {
        dialogsApi.transfer({dialog_id: dialog_id, cat_id: cat_id}).then(({data}) => {
            window.location = "/dialog/"
        });
    },
    fetchCategories: () => dispatch => {
        console.log("fetchCategories");
        dialogsApi.getSelector().then(({data}) => {
            console.log(data);
            dispatch(Actions.setCategories(data.category));
        });
    },
};

export default Actions;
