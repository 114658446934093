import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import DialogItem from "./DialogItem";
import {dialogsActions} from "../redux/actions";
import socket from '../core/socket';

const Dialogs = ({fetchDialogs, fetchSearch, fetchArchive, updateReadedStatus, currentDialogId, items, userId}) => {

    const [unreadCount, setUnreadCount] = useState(-1);

    window.fetchDialogs = fetchDialogs;

    useEffect(() => {
        let total = items.reduce((a, b) => a + (b.total_unreads || 0), 0);

        if (total === 0) {
            setUnreadCount(0);
        }

        if (unreadCount < total) {
            if (unreadCount !== -1) {
                //playIncomingMessageSound();
            }
            setUnreadCount(total);
        }
    }, [items]);

    useEffect(() => {
        fetchDialogs();
        socket.on('SERVER:DIALOG_CREATED', fetchDialogs);
        socket.on('SERVER:NEW_MESSAGE', fetchDialogs);
        socket.on('SERVER:MESSAGES_READED', updateReadedStatus);
        return () => {
            socket.removeListener('SERVER:DIALOG_CREATED', fetchDialogs);
            socket.removeListener('SERVER:NEW_MESSAGE', fetchDialogs);
        };
    }, []);

    const playIncomingMessageSound = () => {
        let audio = new Audio("/assets/sounds/notification.mp3");
        audio.play().then(r => console.log(r));
    }


    const handleSearch = (e) => {
        let text = e.target.value;

        if (text.length > 0) {
            fetchSearch(e.target.value);
        } else {
            fetchDialogs();
        }
    }

    const handleType = e => {
        let type = parseInt(e.target.value);
        if(type === 1){
            fetchDialogs();
        }else{
            fetchArchive();
        }
    }

    const changeTheme = () => {
        let theme = localStorage.getItem("theme");

        if (theme === "dark") {
            document.getElementById("root").classList.remove("dark-theme");
            localStorage.removeItem("theme");
        } else {
            localStorage.setItem("theme", "dark");
            document.getElementById("root").classList.add("dark-theme");
        }
    }

    return (
        <>
            <aside className="sidebar">
                <div className="tab-content">
                    <div className="tab-pane active" id="chats-content">
                        <div className="d-flex flex-column h-100">
                            <div className="hide-scrollbar h-100" id="chatContactsList">
                                <div className="sidebar-header sticky-top p-2">
                                    <div className="sidebar-sub-header">
                                        <div className="input-group">
                                            <select onChange={handleType} className="form-control search border-right-0 transparent-bg pr-0">
                                                <option value="1">Фаол</option>
                                                <option value="2">Архив</option>
                                            </select>
                                        </div>
                                        <div className="input-group">
                                            <input type="text" onChange={handleSearch} className="form-control search border-right-0 transparent-bg pr-0" placeholder="Излаш..."/>
                                            <div className="input-group-append">
                                                <div className="input-group-text transparent-bg border-left-0" role="button">
                                                    <svg className="text-muted hw-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="nav flex-nowrap nav-theme">
                                            <li className="nav-item list-inline-item mr-1">
                                                <div className="nav-link text-muted px-1" title="Theme" role="button" onClick={() => changeTheme()}>
                                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="moon" className="svg-inline--fa fa-moon fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path fill="currentColor" d="M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z"/>
                                                    </svg>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <ul className="contacts-list" id="chatContactTab" data-chat-list="">
                                    {
                                        items ? items.map(item => <DialogItem currentDialogId={currentDialogId} item={item} key={Math.random()}/>) : ""
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
};

export default connect(
    ({dialogs}) => dialogs,
    dialogsActions,
)(Dialogs);
