import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";

import App from "./App";
import {userActions} from "./redux/actions";

import store from "./redux/store";

store.dispatch(userActions.fetchUserData());

if (localStorage.getItem("theme") === "dark") {
    document.getElementById("root").classList.add("dark-theme");
}

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App/>
        </Router>
    </Provider>,
    document.getElementById("root")
);
