import axios from "axios";
import Const from "./Const";


// axios.defaults.baseURL = "http://localhost:3003";

axios.defaults.baseURL = Const.URL;

axios.defaults.headers.common["token"] = window.localStorage.token;

window.axios = axios;

export default axios;
