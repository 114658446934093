import React from 'react';
import {generateAvatarFromHash} from "../utils/helpers";
import Const from "../core/Const";

const Avatar = ({user, type}) => {
    // eslint-disable-next-line no-unused-vars
    const {color, colorLighten} = generateAvatarFromHash(user.id);
    let firstChar = "";
    let endChar = "";

    if (user.last_name[0]) {
        firstChar = user.last_name[0].toUpperCase();
    }


    let avatar = user.avatar;
    let url = Const.URL + "/" + user.avatar;

    if (type === "lg") {
        return (
            <>
                <div className="avatar avatar-lg bg-info text-light" style={{background: `linear-gradient(135deg, ${color} 0%, ${color} 100%)`}}>
                    {avatar ? <img src={url} alt=""/> : <span>{firstChar + endChar}</span>}
                </div>
            </>
        )
    }

    if (type === "sm") {
        return (
            <>
                <div className="avatar avatar-sm bg-info text-light" style={{background: `linear-gradient(135deg, ${color} 0%, ${color} 100%)`}}>
                    {avatar ? <img src={url} alt=""/> : <span>{firstChar + endChar}</span>}
                </div>
            </>
        );
    }
    if (type === "xl") {
        return (
            <>
                <div className="avatar avatar-xl bg-info text-light" style={{background: `linear-gradient(135deg, ${color} 0%, ${color} 100%)`}}>
                    {avatar ? <img src={url} alt=""/> : <span>{firstChar + endChar}</span>}
                </div>
            </>
        );
    }

    return (
        <>
            <div className="avatar bg-info text-light" style={{background: `linear-gradient(135deg, ${color} 0%, ${color} 100%)`}}>
                {avatar ? <img src={url} alt=""/> : <span>{firstChar + endChar}</span>}
            </div>
        </>
    );
};

export default Avatar;
